.wysiwyg {
	@apply font-body;

	a:not(.button):not(.quiet) {
		@apply underline underline-offset-4 decoration-lightGrey hover:decoration-dark;
	}

	ul {
		@apply break-inside-avoid;
	}

	ul:not(.sexy-list) {
		@apply list-disc pl-5;
	}

	ol {
		@apply list-none pl-0 break-inside-avoid;

		li {
			@apply mb-2 last:mb-0;

			&::before {
				@apply pr-2;
			}
		}

		counter-reset: ol;

		> li {
			counter-increment: ol;

			&::before {
				content: counter(ol) ".";
			}
		}

		ol {
			@apply pt-2;

			counter-reset: ol2;

			> li {
				counter-increment: ol2;

				@apply pl-5;

				&::before {
					content: counter(ol) "." counter(ol2) ".";
				}
			}

			ol {
				counter-reset: ol3;

				> li {
					counter-increment: ol3;

					@apply pl-10;

					&::before {
						content: counter(ol) "." counter(ol2) "." counter(ol3) ".";
					}
				}
			}
		}
	}

	blockquote {
		@apply font-headings text-2xl leading-snug;
	}

	.center {
		@apply mx-auto;
	}

	.half-width {
		@apply md:w-1/2;
	}

	.full-width {
		@apply w-full;
	}

	.left {
		@apply float-left mr-5;
	}

	.right {
		@apply float-right ml-5;
	}

	table {
		@apply w-full border-collapse;

		td,
		th {
			@apply text-left border border-primary/20 p-5;
		}

		th {
			@apply font-bold;
		}

		> tr:first-child td,
		> tr:first-child th,
		> tbody:first-child tr:first-child td,
		> tbody:first-child tr:first-child th {
			@apply bg-primary/50 font-bold;
		}
	}

	hr {
		@apply border-grey my-10;
	}

	& > p:not(:empty),
	& > ul:not(:empty),
	& > ol:not(:empty),
	& > blockquote:not(:empty),
	& > table,
	& > img,
	& > figure {
		@apply my-5 first:mt-0 last:mb-0;
	}

	img,
	figure {
		@apply max-w-full rounded-sm;
	}

	figcaption {
		@apply italic text-sm mt-2;
	}

	h2 {
		@apply font-headings text-dark font-semibold text-[clamp(30px,4vw,36px)] leading-[1.1] my-5 first:mt-0 last:mb-0;
	}

	h3 {
		@apply font-headings text-dark font-medium text-[clamp(24px,3vw,30px)] leading-[1.1] my-5 first:mt-0 last:mb-0;
	}

	h4 {
		@apply font-bold text-dark text-[clamp(16px,1.4vw,18px)] leading-[1.2] my-5 first:mt-0 last:mb-0;
	}

	.responsive-object {
		@apply relative rounded overflow-hidden my-8 first:mt-0 last:mb-0;

		iframe,
		object,
		embed {
			@apply absolute top-0 bottom-0 left-0 right-0 w-full h-full;
		}
	}
}
