/* PLACEHOLDER */

input::placeholder {
	color: theme("colors.grey") !important;
	opacity: 1 !important;
}

select:focus,
input:focus {
	@apply outline outline-offset-0;
}
