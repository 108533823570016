.btn {
	@apply inline-flex items-center justify-center gap-x-2.5;
	@apply font-headings font-medium leading-none;
	@apply p-5 rounded-lg;
	@apply transition duration-300;

	svg {
		@apply inline-block w-3 min-w-3 h-3 min-h-3;
		@apply transition duration-300 last:group-hover:translate-x-1;
	}
}

.btn-small {
	@apply p-3 text-sm;
}

.btn-between {
	@apply justify-between gap-x-5;
}

.btn-default {
	@apply bg-dark text-white border border-dark;
}

.btn-border {
	@apply bg-transparent text-white border border-white;
}

.btn-white {
	@apply bg-white text-dark border border-white;

	svg {
		@apply fill-dark;
	}

	&:hover {
		@apply ring-[6px] ring-white/50;
	}
}

.btn-default,
.btn-border {
	svg {
		@apply fill-white;
	}

	&:hover {
		@apply bg-white text-dark;

		svg {
			@apply fill-dark;
		}
	}
}
