/* Tailwind's base styles */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/** Theme styles */
@import "typo.scss";
@import "button.scss";
@import "form.scss";
@import "fancybox.scss";
@import "slideshow.scss";

/* FONTS */
@font-face {
	font-family: "Source Serif 4";
	src:
		url("../fonts/SourceSerif4-Regular.woff2") format("woff2"),
		url("../fonts/SourceSerif4-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Source Serif 4";
	src:
		url("../fonts/SourceSerif4-Italic.woff2") format("woff2"),
		url("../fonts/SourceSerif4-Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Source Serif 4";
	src:
		url("../fonts/SourceSerif4-Bold.woff2") format("woff2"),
		url("../fonts/SourceSerif4-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Source Serif 4";
	src:
		url("../fonts/SourceSerif4-BoldItalic.woff2") format("woff2"),
		url("../fonts/SourceSerif4-BoldItalic.woff") format("woff");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Bricolage Grotesque";
	src:
		url("../fonts/BricolageGrotesque-Regular.woff2") format("woff2"),
		url("../fonts/BricolageGrotesque-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Bricolage Grotesque";
	src:
		url("../fonts/BricolageGrotesque-Medium.woff2") format("woff2"),
		url("../fonts/BricolageGrotesque-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Bricolage Grotesque";
	src:
		url("../fonts/BricolageGrotesque-SemiBold.woff2") format("woff2"),
		url("../fonts/BricolageGrotesque-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Outfit;
	src:
		url("../fonts/Outfit-Light.woff2") format("woff2"),
		url("../fonts/Outfit-Light.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Outfit;
	src:
		url("../fonts/Outfit-Medium.woff2") format("woff2"),
		url("../fonts/Outfit-Medium.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/* GENERAL */
body {
	font-family: theme("fontFamily.body");
	font-size: theme("fontSize.base");
	font-weight: 400;
	color: theme("colors.body");
	background: theme("colors.white");
}

*:focus-visible {
	@apply outline-2 outline-primary outline-offset-[3px];
}

/* CONTAINER */
.ctn {
	@apply px-[20px];

	@media (min-width: theme("screens.sm")) {
		@apply px-[7vw];
	}

	@media (min-width: 1600px) {
		padding-left: calc((100vw - 1352px) / 2);
		padding-right: calc((100vw - 1352px) / 2);
	}
}

/* GRID */
.gr {
	@apply grid grid-cols-12 gap-x-1 sm:gap-x-5 gap-y-8 lg:gap-10;
}

/* SECTION */
.section-margin {
	@apply my-16 md:my-24 lg:my-32;

	header + & {
		@apply mt-10 md:mt-16 lg:mt-20;
	}
}

.section-padding {
	@apply py-16 md:py-24 lg:py-32;
}

.section-padding-small {
	@apply py-16 md:py-24;
}

// Avec image
.hero-padding {
	@apply pt-44 md:pt-52 lg:pt-60 pb-12 md:pb-20;
}

// Sans image
.header-padding {
	@apply pt-44 md:pt-52 lg:pt-60;
}

/* BASICS */
hr {
	@apply border-light my-6;
}

/* LIST */
.sexy-list {
	@apply list-none pl-0;

	li {
		@apply relative pl-[1.5em] mb-[0.75em];

		&::before {
			@apply content-[""] absolute top-[0.4em] left-0 w-[0.8em] h-[0.65em] bg-no-repeat bg-left bg-transparent;

			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 7'%3E%3Cpath fill='%23000000' fill-rule='evenodd' d='M5.20696 3.79289 8.99985-6e-8 10.4141 1.41421 5.20696 6.62132-.00015074 1.41421 1.41406-4e-7 5.20696 3.79289Z' clip-rule='evenodd'/%3E%3C/svg%3E");
		}
	}
}

/* TIPPY */
.tippy-box {
	@apply font-small;
}

/* SUBMITTING */
.submitting {
	@apply relative;

	&::after {
		content: "";
		position: absolute;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		width: 50px;
		height: 50px;
		border-radius: 50px;
		animation: spin 2s linear infinite;

		@apply border-4 border-primary/50 border-t-primary;
	}
}

/* PAGINATION */
.page-item {
	@apply opacity-80 transition-opacity;

	&:hover {
		@apply opacity-100;
	}

	&.active {
		@apply font-bold opacity-100;
	}
}
